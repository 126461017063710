exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dettelagervi-js": () => import("./../../../src/pages/dettelagervi.js" /* webpackChunkName: "component---src-pages-dettelagervi-js" */),
  "component---src-pages-fagdag-js": () => import("./../../../src/pages/fagdag.js" /* webpackChunkName: "component---src-pages-fagdag-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internship-js": () => import("./../../../src/pages/internship.js" /* webpackChunkName: "component---src-pages-internship-js" */),
  "component---src-pages-sommerjobb-js": () => import("./../../../src/pages/sommerjobb.js" /* webpackChunkName: "component---src-pages-sommerjobb-js" */),
  "component---src-pages-utviklere-js": () => import("./../../../src/pages/utviklere.js" /* webpackChunkName: "component---src-pages-utviklere-js" */),
  "component---src-pages-utviklingsleder-js": () => import("./../../../src/pages/utviklingsleder.js" /* webpackChunkName: "component---src-pages-utviklingsleder-js" */),
  "component---src-pages-utviklingsledertrondheim-js": () => import("./../../../src/pages/utviklingsledertrondheim.js" /* webpackChunkName: "component---src-pages-utviklingsledertrondheim-js" */),
  "component---src-templates-developer-template-js": () => import("./../../../src/templates/developerTemplate.js" /* webpackChunkName: "component---src-templates-developer-template-js" */),
  "component---src-templates-employee-template-js": () => import("./../../../src/templates/employeeTemplate.js" /* webpackChunkName: "component---src-templates-employee-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

